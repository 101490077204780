* {
	margin: 0;
	padding: 0;
	vertical-align: top;
}

.revert-zoom {
	zoom: 0.8;
}

@media print {
	app-navi {
		display: none !important;
	}
}
